@import "~antd/lib/style/themes/index.less";
@import "~hive-admin/src/antd.less";

@shopnosis-black: #000;
@shopnosis-primary: #4885ff;
@shopnosis-primary-faded-85: rgba(@shopnosis-primary, 0.85);
@shopnosis-primary-faded-80: rgba(@shopnosis-primary, 0.8);

@shopnosis-black-faded: rgba(@shopnosis-black, 0.7);

@black-faded: rgba(@shopnosis-black, 1);
@prefix-color: rgba(@text-color, 0.6);

// @error-color: @shopnosis-primary;
// @btn-danger-color: #fff;
// @btn-danger-bg: @shopnosis-primary;
// @btn-danger-border: @shopnosis-primary;

@primary-color: @shopnosis-primary;
@secondary-color: @shopnosis-primary;

@heading-color: @shopnosis-primary;
// @text-color: @shopnosis-black;

@heading-color: @shopnosis-black;
@title-color: @shopnosis-black;
@text-color: @shopnosis-black;

@border-radius-base: 16px;

[data-component="PageSingleVisual"] {
  display: flex;
  flex-direction: column;
  flex: 1;

  >.ant-form,
  >.ant-result>.ant-result-extra .ant-form {
    display: flex;
    flex-direction: column;
    flex: 1;
  }

  .actions {
    &.actions-bottom {
      >.ant-divider-horizontal {
        margin-top: 0px;
        margin-bottom: 18px;
      }
    }
  }

  .visual-step-title {
    &:hover {
      color: @primary-color;
    }
  }

  form textarea.ant-input {
    margin-bottom: 0px;

    &::placeholder {
      color: #999;
    }
  }
}
