@import "~antd/lib/style/themes/index.less";
@import "~hive-admin/src/antd.less";

@shopnosis-black: #000;
@shopnosis-primary: #4885ff;
@shopnosis-primary-faded-85: rgba(@shopnosis-primary, 0.85);
@shopnosis-primary-faded-80: rgba(@shopnosis-primary, 0.8);

@shopnosis-black-faded: rgba(@shopnosis-black, 0.7);

@black-faded: rgba(@shopnosis-black, 1);
@prefix-color: rgba(@text-color, 0.6);

// @error-color: @shopnosis-primary;
// @btn-danger-color: #fff;
// @btn-danger-bg: @shopnosis-primary;
// @btn-danger-border: @shopnosis-primary;

@primary-color: @shopnosis-primary;
@secondary-color: @shopnosis-primary;

@heading-color: @shopnosis-primary;
// @text-color: @shopnosis-black;

@heading-color: @shopnosis-black;
@title-color: @shopnosis-black;
@text-color: @shopnosis-black;

@border-radius-base: 16px;

[data-component="PageArchiveTable"] {
  .ant-table-scroll {
    position: relative;

    overflow-x: initial;
    overflow-y: initial;

    &:before,
    &:after {
      content: '';
      position: absolute;
      top: 0; bottom: 0;
      width: 40px;
    }

    &:before {
      left: -40px;
      background: linear-gradient(to right, white, transparent);
    }

    &:after {
      right: -40px;
      background: linear-gradient(to left, white, transparent);
    }
  }

  .ant-table-body {
    margin: 0 -40px 5px;
    padding: 0 40px 15px;
  }

  table {
    border: 1px solid #d9d9d9 !important;
    border-radius: 10px;
    overflow: hidden;

    th:last-of-type,
    td:last-of-type {
      border-right: none;
    }

    tr:last-of-type td {
      border-bottom: none;
    }
  }
}
