@import "~antd/lib/style/themes/index.less";
@import "~hive-admin/src/antd.less";

@shopnosis-black: #000;
@shopnosis-primary: #4885ff;
@shopnosis-primary-faded-85: rgba(@shopnosis-primary, 0.85);
@shopnosis-primary-faded-80: rgba(@shopnosis-primary, 0.8);

@shopnosis-black-faded: rgba(@shopnosis-black, 0.7);

@black-faded: rgba(@shopnosis-black, 1);
@prefix-color: rgba(@text-color, 0.6);

// @error-color: @shopnosis-primary;
// @btn-danger-color: #fff;
// @btn-danger-bg: @shopnosis-primary;
// @btn-danger-border: @shopnosis-primary;

@primary-color: @shopnosis-primary;
@secondary-color: @shopnosis-primary;

@heading-color: @shopnosis-primary;
// @text-color: @shopnosis-black;

@heading-color: @shopnosis-black;
@title-color: @shopnosis-black;
@text-color: @shopnosis-black;

@border-radius-base: 16px;

@includeLessVars: {
  display: none !important;
  @vars: primary-color,secondary-color,primary-1,primary-2,background-color-light,text-color,disabled-color,error-color,success-color,red-2,border-color-base,border-radius-base;
  .-(@i: length(@vars)) when (@i > 0)
  {
    @name: extract(@vars, @i);
    --@{name}: @@name !important;
    .-((@i - 1));
  }
  .-;
}

@import '~antd/lib/style/core/index.less';
@import '~antd/lib/style/components.less';

#__LESSVARS__ {
  @includeLessVars();
}

#admin #sidebar .ant-menu-item {
  &:after { display: none !important }
  border-radius: 10px;
}

#admin {
  .ant-empty-description { opacity: 0.35 }
  .field-wysiwyg-wrapper {
    border: 1px solid #d9d9d9;
    border-radius: 4px;
    overflow: auto;

    .rdw-editor-main {
      padding: 0 10px;
      border: none;
    }
    .rdw-editor-toolbar {
      margin-left: 0;
      margin-right: 0;
      border: none;
      border-bottom: 1px solid #d9d9d9;
      padding: 5px;
    }
    .rdw-link-decorator-wrapper {
      span {
        color: @text-color;
        text-decoration: underline;
      }
    }

    h1, h2, h3 {
      color: @text-color;
    }
    h1 {
      font-size: 1.7em;
    }
    .rdw-colorpicker-option {
      width: 40px !important;
    }

    .rdw-image-modal {
      left: -195px;
      @media (max-width: 1170px) {
        left: 5px !important;
      }
    }

    .rdw-link-modal {
      left: -195px;
      @media (max-width: 1135px) {
        left: 5px !important;
      }
    }
  }

  .ant-col-1 {
    width: auto !important;
    @media (max-width: 600px) {
      width: 100% !important;
    }
    .ant-upload-picture-card-wrapper {
      display: block !important;
    }
  }

  .ant-form-item-required::before {
    display: none;
  }

  @media (min-width: 992px) {
    [data-input-name="showFooter"] .ant-form-item-control,
    [data-input-name="showHeader"] .ant-form-item-control {
      display: flex;
      justify-content: flex-end;

      .ant-form-item-children {
        display: flex;
        align-items: center;
        flex-direction: row-reverse;
        gap: 15px;
      }
    }
  }

  .ant-input-affix-wrapper .ant-input-prefix > span,
  .ant-form-item-children > span:nth-child(2),
  .ant-col.ant-form-item-label > label,
  .inline-label-before {
    color: @prefix-color;
  }

  .field-address-center-icon {
    color: @primary-color;
  }

  .ant-upload-picture-card-wrapper {
    display: flex;
    flex-wrap: wrap;
  }

  .ant-upload-list-item.ant-upload-list-item-list-type-picture-card {
    background: hsl(0, 0%, 96%);
  }

  .anticon.anticon-file.ant-upload-list-item-icon {
    path:first-child {
      fill: @shopnosis-primary;
      opacity: 0.1;
    }

    path:last-child {
      fill: @shopnosis-primary;
    }
  }
}

@media (min-width: 1024px) {
  .ant-steps-item-description {
    white-space: nowrap !important;
  }
}

#main {
  outline: none !important;
}

[data-page-path="/videosessions/:id"] main > [data-component="PageSingle"] {
  max-width: 1200px !important;
}

.ant-slider-mark-text-active {
  opacity: 0.4;
  font-size: 12px;
  margin-top: 4px;
}

.ant-popover-message-title {
  padding: 0px;
}

.ant-popover-buttons {
  text-align: center;
}

.ant-popover-buttons button:first-child {
  margin-left: 0 !important;
}

.ant-layout-sider > .ant-layout-sider-children > .menus *:not(.ant-menu-item-selected *) {
  color: #666;
}

// .ant-menu.menu-navigation.ant-menu-light.ant-menu-root.ant-menu-inline {
//   opacity: 0.6;
// }
